import classNames from "classnames";
import CheckIcon from "../../../assets/icons/icon-check.svg";

export const Input = (
  props: Omit<React.InputHTMLAttributes<HTMLInputElement>, "className">
) => {
  return (
    <input
      {...props}
      className="border border-teal-300 rounded-xl px-3 py-[10px] placeholder:text-gray-300 text-gray-800 text-sm"
    />
  );
};

export const FullWidthInput = (
  props: Omit<React.InputHTMLAttributes<HTMLInputElement>, "className">
) => {
  return (
    <input
      {...props}
      className="w-full border border-gray-100 focus:outline-teal-500 rounded-xl px-3 py-[10px] placeholder:text-gray-300 text-gray-800 text-sm"
    />
  );
};

interface CheckboxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "className" | "type"
  > {
  label?: React.ReactNode;
}

// TODO: needs hover, focus states
export const Checkbox = ({ label, ...rest }: CheckboxProps) => {
  const { checked } = rest;
  const checkbox = (
    <>
      <input
        {...rest}
        type="checkbox"
        className="absolute opacity-0 cursor-pointer"
      />
      <div className="border-2 border-teal-400 rounded-lg w-5 aspect-square cursor-pointer select-none before:w-5 h-5">
        {checked && <img src={CheckIcon} alt="" />}
      </div>
    </>
  );

  if (!label) return checkbox;

  return (
    <label className="font-medium text-sm flex items-center gap-x-3">
      {checkbox}
      {label}
    </label>
  );
};

interface RadioOptionProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "className" | "type"
  > {
  value: string;
  label: string;
  subLabel?: string;
  disabled?: boolean;
}

export const RadioOption = ({
  disabled,
  value,
  label,
  subLabel,
  ...rest
}: RadioOptionProps) => {
  return (
    <label className="flex items-center text-gray-400">
      <div className="h-12 w-12 flex items-center justify-center">
        <input
          disabled={disabled}
          value={value}
          type="radio"
          name="privacy"
          className="accent-teal-500"
          {...rest}
        />
      </div>
      <div className="inline-block">
        <span className="font-semibold text-sm">{label}</span>
        <br />
        <span className="text-xs">{subLabel}</span>
      </div>
    </label>
  );
};

interface NumberPasswordInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "className" | "type" | "pattern"
  > {
  error?: boolean;
}

export const NumberPasswordInput = ({
  error,
  ...rest
}: NumberPasswordInputProps) => {
  return (
    <input
      {...rest}
      type="password"
      pattern="[0-9]*"
      inputMode="numeric"
      maxLength={1}
      className={classNames(
        "rounded-xl px-3 py-[10px] placeholder:text-gray-300 font-semibold text-4xl text-center w-12 h-16",
        {
          "border border-teal-300 text-teal-400": !error,
          "border-2 border-red-300 text-red-400": error,
        }
      )}
    />
  );
};

interface SelectablePillProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "className" | "type" | "name"
  > {
  label: string;
  groupName?: string;
}

export const SelectablePill = ({
  label,
  groupName,
  ...rest
}: SelectablePillProps) => {
  const { checked } = rest;
  return (
    <label>
      <input
        type="radio"
        name={groupName}
        className="absolute opacity-0 cursor-pointer"
        {...rest}
      />
      <div
        className={`text-sm inline-block rounded-md cursor-pointer select-none py-2 px-4 mr-3 mb-2 border ${
          checked
            ? "border-teal-50 text-black font-bold bg-[#DEF3F4]"
            : "border-[#7A757F]/60 text-[#191C1A]"
        } `}
      >
        {label}
      </div>
    </label>
  );
};
