import { Routes, Route } from "react-router-dom";
import { PageRoute } from "./util/constants";
import { PageTitle } from "./components/page-title";
import { ResetPasswordPage } from "./pages/reset-password/ResetPassword";
import { SunsetWebapp } from "./pages/sunset-webapp/SunsetWebapp";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={PageRoute.RESET_PASSWORD}
        element={
          <PageTitle title="Reset Password">
            <ResetPasswordPage />
          </PageTitle>
        }
      />
      <Route path="*" element={<SunsetWebapp />} />
    </Routes>
  );
};