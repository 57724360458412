import AppStore from "../../../assets/buttons/app-store.svg";
import GooglePlay from "../../../assets/buttons/google-play.svg";
import { ButtonLink, ButtonColor } from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import SunnieAirplaneWithMail from "../../../assets/illustrations/sunnie-airplane.svg";
import { Card } from "../../components/card/Card";

export const SunsetWebapp = () => {
  return (
    <>
    <div className="h-screen w-full bg-white items-center bg-top bg-no-repeat bg-contain md:bg-cover bg-cloud-low xs:bg-cloud-mid sm:bg-cloud-high md:bg-cloud-veryhigh flex justify-center">
      <div className="h-screen w-full xs:container px-6 flex flex-col items-center md:relative">
        <ButtonLink
          to="mailto:hello@flouriship.com"
          color={ButtonColor.White}
          className="absolute top-10 right-6 font-bold text-sm flex items-center gap-2"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            className="text-teal-700"
          />
          Contact Us
        </ButtonLink>
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col md:flex-row md:items-end md:gap-12 md:pb-0 md:mt-[200px]">
            <div className="flex flex-col md:items-end">
              <img src={SunnieAirplaneWithMail} className="mt-10 w-[160px] xxs:w-[206px] xs:w-[244px] md:" alt="" />
              <Card className="mt-7 mb-12 w-full bg-sky py-6 max-w-[360px] py-6 px-7">
                <h1 className="text-[22px] font-bold">🎉 🎉 🎉 <br />Flourish has a new home!</h1>
                <p className="pt-3 text-sm">Download our new app for:
                  <ul className="pl-6 list-disc">
                    <li>More intuitive interface</li>
                    <li>Better interaction with Sunnie</li>
                    <li>More personalized insights</li>
                  </ul>
                </p>
              </Card>
            </div>
            <div className="flex flex-col gap-5 w-full max-w-[360px] md:py-[35px] md:my-7">
              {/* App store buttons */}
              <div className="flex justify-between w-full gap-2">
                <ButtonLink to="https://apps.apple.com/us/app/flourish-happiness-in-action/id6474883669" usingDefaultStyles={false}>
                  <img src={AppStore} alt="" />
                </ButtonLink>
                <ButtonLink to="https://play.google.com/store/apps/details?id=com.flourish.app" usingDefaultStyles={false}>
                  <img src={GooglePlay} alt="" />
                </ButtonLink>
              </div>
              <p className="text-xs text-center">Building a product from scratch is not easy, and it simply won't be possible without YOU!</p>
              <p className="text-sm font-bold text-center">Thank you for being our earliest supporters!</p>
            </div>
          </div>

          <Card className="my-14 md:my-0 max-w-[360px] w-full bg-sky py-6 md:max-w-[730px] pt-6 pb-9 px-7 md:mb-[132px]">
            <h1 className="text-[22px] font-bold">FAQ</h1>

              <ul className="pt-6 flex flex-col gap-6 text-sm">
                <li>
                  <h2 className="font-bold">Do I need to create a new account in the new mobile app?</h2>
                  <p className="pt-[2px]">No, your current account remains active. Simply log in with your existing credentials.</p>
                </li>
                <li>
                  <h2 className="font-bold">Will I lose my existing data?</h2>
                  <p className="pt-[2px]">Absolutely not.</p>
                </li>
                <li>
                  <h2 className="font-bold">What happens if I don’t transition by the completion date?</h2>
                  <p className="pt-[2px]">We encourage transitioning TODAY to continue enjoying Flourish without interruption, benefiting from the latest features. We'll be bidding farewell to the current version, which will be discontinued on or before May 1, 2024.</p>
                </li>
                <li>
                  <h2 className="font-bold">How can I provide feedback on the mobile app?</h2>
                  <p className="pt-[2px]">Your feedback is invaluable to us. Please share your thoughts through the “Give Feedback” option on the Profile page within the mobile app.</p>
                </li>
              </ul>
            </Card>
          </div>
      </div>
    </div>
    </>
  );
};