export const emailAddressPattern =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// Journey Signup Date cutoff for BeWell - May 19th, 12:00am PST
// export const JOURNEY_SIGNUP_DATE = "2023-05-19T07:00:00Z";

// Journey Signup Date cutoff for BeWell - May 18th, 7:00pm PST
export const JOURNEY_SIGNUP_DATE = "2023-05-30T07:00:00Z";

export const PRIVACY_SELF = "self";

export const PRIVACY_URL = "https://www.flouriship.com/privacy-policy";
export const TERMS_URL = "https://www.flouriship.com/terms-conditions";

export enum PageRoute {
  HOME = "/",
  // Need login; used in navigation bar
  ACTIVITY = "/activity",
  COMMUNITY = "/community",
  LIBRARY = "/library",
  INSIGHTS = "/insights",
  PROFILE = "/me",
  // Need login; not used in navigation bar
  COMMUNITY_POST = "/postToCommunity",
  CELEBRATE_COMMUNITY_POST = "/celebrateCommunityPost",
  BUDDIES = "/buddies",
  // No need to login
  LOGIN = "/login",
  START = "/start",
  SIGNOUT = "/signout",
  INVITATION_CODE = "/invitation-code",
  RESET_PASSWORD = "/resetPassword",
  CREATE = "/create",
  ADMIN = "/admin",
}

// This enum is for handling `popupState` for the Buddy-Add popups
export enum BuddyPopupState {
  NO_POPUP = -1, // Default blank state (to show no popup)
  INITIAL_PROMPT = 0, // "Accept [Name]'s invite?"
  CONFIRMATION = 1, // "You and [Name] are now friends!"
  BUDDY_EXISTS_ERROR = 2, // "You are already friends"
  INVALID_LINK_ERROR = 3, // "This link is invalid"
}

// When accounts are created, a randomly selected avatar will be assigned corresponding to files in assets/avatars
export const AVATARS = [
  "default.svg",
  "1.svg",
  "2.svg",
  "3.svg",
  "4.svg",
  "5.svg",
  "6.svg",
  "7.svg",
  "8.svg",
  "9.svg",
  "10.svg",
];
