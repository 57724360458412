import { ReactNode, useEffect } from "react";

export const PageTitle = ({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) => {
  useEffect(() => {
    document.title = title ? `Flourish | ${title}` : "Flourish";
  }, [title]);
  return <>{children}</>;
};
