import { InvitationCodeType } from "../../../client-server-shared/enums";
import { IInvitationCode, IOrgApplyRequest, IInvitationCodeGetCodeRequest } from "../../../client-server-shared/response-types";
import getData from "../helper/getData";
import postData from "../helper/postData";
import {
  APPLY_INVITATION_CODE,
  CREATE_INVITATION_CODE,
  GET_INVITATION_CODE,
  GET_INVITATION_CODE_LIST,
  VALIDATE_INVITATION_CODE,
} from "../helper/requestConstants";

export async function validateCode(code: string, codeType: InvitationCodeType): Promise<boolean> {
  const response = await postData(VALIDATE_INVITATION_CODE, { code, codeType });
  return response.success;
}

export async function generateAndSendCode(orgName: string,  emails: string): Promise<boolean> {
  const response = await postData(CREATE_INVITATION_CODE, { orgName, emails });
  return response.success;
}

export async function applyOrgInviteCode(req: IOrgApplyRequest): Promise<boolean> {
  const response = await postData(APPLY_INVITATION_CODE, req);
  return response.success;
}

export async function getCode(req: IInvitationCodeGetCodeRequest): Promise<IInvitationCode> {
  const response = await postData(GET_INVITATION_CODE, req);
  return response.code;
}

export async function getCodesByOrg(orgName: string): Promise<IInvitationCode[]> {
  const response = await getData(GET_INVITATION_CODE_LIST, { orgName });
  return response.codes;
}
