import { ReactNode } from "react";
import classNames from "classnames";
import {
  BackgroundImageConfig,
  CardColor,
  CardColorType,
  getBackgroundImageStyles,
} from "./card-util";

interface CardProps {
  color?: CardColorType;
  backgroundImage?: BackgroundImageConfig;
  border?: boolean;
  grayed?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Card = ({
  color = CardColor.Gray,
  backgroundImage,
  border,
  grayed,
  children,
  className,
}: CardProps) => {
  const { classNames: bgClassNames, styles: bgStyles } = backgroundImage
    ? getBackgroundImageStyles(backgroundImage)
    : { classNames: "", styles: {} };
  return (
    <div
      className={classNames(
        "relative rounded-3xl",
        color?.background,
        color?.text,
        bgClassNames,
        className,
        { "border border-solid border-[#D2DDDF]": border } // If border is `true` apply these styles
      )}
      style={bgStyles}
    >
      {children}
      {grayed && (
        <div className="bg-card-grayed mix-blend-hue absolute top-0 left-0 right-0 bottom-0 rounded-3xl" />
      )}
    </div>
  );
};
