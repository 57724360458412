import { useState } from "react";
import { Input } from "../../components/input";
import { Button, ButtonSize } from "../../components/button";
import { sendResetPasswordEmail } from "../../controllers/ajax/AuthController";
import { ErrorMessage } from "../../components/error";
import { loginPageLink } from "../../util/relative-links";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const EmailPromptPage = () => {
    const navigate = useNavigate();
    const [emailSent, setEmailSent] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const sendPasswordResetEmail = () => {
        sendResetPasswordEmail(emailAddress)
            .then(() => {
                setEmailSent(true);
            }).catch((e) => {
                console.error(e);
                setErrorMessage("Sorry, an error occurred while sending the password reset email. Please try again later.");
            })
    }

    return (
        <div className="flex flex-col w-[300px]"
        >
            {emailSent ?
                <div>
                    <h1 className="flex gap-2 items-center font-bold text-2xl text-teal-600 mb-2">
                        <FontAwesomeIcon
                            icon={icon({ name: "envelope" })}
                            className="text-teal-500"
                        />
                        Check your email
                    </h1>
                    <p className="mb-8">If we find a match, an email will be sent to your inbox with a link to reset your password.</p>
                    <Button onClick={() => {
                        navigate(loginPageLink());
                    }} size={ButtonSize.Full}>
                        Go to Sign In
                    </Button>
                </div>
                : <div>
                    <h1 className="font-bold text-2xl text-teal-600 mb-2">
                        Enter your email
                    </h1>
                    <p className="mb-8">If we find a match, an email will be sent to your inbox with a link to reset your password.</p>
                    <div className="flex flex-col space-y-1 mb-10">
                        <label htmlFor="emailAddress" className="text-sm font-medium">
                            Email
                        </label>
                        <Input
                            id="emailAddress"
                            type="text"
                            onChange={(e) => setEmailAddress(e.target.value)}
                            placeholder="example@email.com"
                        />
                    </div>
                    <Button className="mb-2" onClick={() => {
                        sendPasswordResetEmail();
                    }} size={ButtonSize.Full} disabled={!emailAddress}>
                        Next
                    </Button>
                    <ErrorMessage message={errorMessage} />
                </div>
            }
        </div>
    );
}