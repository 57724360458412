import { PageRoute } from "./constants";

export const activityPageLink = (): string => {
  return PageRoute.ACTIVITY;
};

export const loginPageLink = (): string => {
  return PageRoute.LOGIN;
};

export const createPageLink = (): string => {
  return PageRoute.CREATE;
};

export const startPageLink = (): string => {
  return PageRoute.START;
};

export const signoutPageLink = (): string => {
  return PageRoute.SIGNOUT;
};

export const insightsPageLink = (): string => {
  return PageRoute.INSIGHTS;
};

export const communityPageLink = (): string => {
  return PageRoute.COMMUNITY;
};

export const postToCommunityPageLink = (): string => {
  return PageRoute.COMMUNITY_POST;
};

export const celebrateCommunityPostPageLink = (): string => {
  return PageRoute.CELEBRATE_COMMUNITY_POST;
};

export const buddiesPageLink = (): string => {
  return PageRoute.BUDDIES;
};

export const profilePageLink = (): string => {
  return PageRoute.PROFILE;
};

export const libraryPageLink = (): string => {
  return PageRoute.LIBRARY;
};

export const buddyAddLink = (): string => {
  return `https://${window.location.host}/start?referrer=`;
};

export const resetPasswordPageLink = (): string => {
  return PageRoute.RESET_PASSWORD;
};
