import PotatoOpenImage from "../../../assets/illustrations/potato-open.svg";
import { EmailPromptPage } from "./EmailPrompt";
import { ResetPasswordForm } from "./ResetPasswordForm";


// This is the page for the reset password flow.
export const ResetPasswordPage = () => {
    const params = new URLSearchParams(window.location.search);
    const resetToken = params.get("token");

    return (
        <div
            className="flex h-screen w-screen items-center justify-center bg-no-repeat bg-right-bottom"
            style={{ backgroundImage: `url(${PotatoOpenImage})` }} >
            {resetToken ? <ResetPasswordForm resetToken={resetToken} /> : <EmailPromptPage />}
        </div>
    );
}
