import { IUserOrigin } from "../../../client-server-shared/response-types";
import postData from "../helper/postData";
import patchData from "../helper/patchData";
import {
  GET_IS_EMAIL_ADDRESS_AVAILABLE_URL,
  REGISTER_LOGIN_USER_URL,
  RESET_PASSWORD_URL,
  SEND_RESET_PASSWORD_EMAIL_URL,
  SIGNOUT_USER_URL,
  ADD_USER_ORIGIN_INFO_URL,
} from "../helper/requestConstants";
import { unsubscribeNotifications, unsubscribeFCMNotifications } from "../../helper/pushNotifications";
import { AVATARS } from "../../util/constants";

// TODO: return differently if the server failed vs if the user put in incorrect account info
export async function loginUser(emailAddress: string, password: string): Promise<boolean> {
  const [loginResponse] = await Promise.all([
    postData(REGISTER_LOGIN_USER_URL, {
      emailAddress: emailAddress,
      password: password,
      isSigningUp: false,
    }),
  ]);

  return Boolean(loginResponse.success);
  // TODO: loginResponse.errors should give the error message to render
}
// TODO: return differently if the server failed vs if the user put in bad info
export async function registerUser(
  emailAddress: string,
  password: string,
  displayName: string,
  allowDataCollection: boolean,
  referrer: string | null
): Promise<boolean> {
  // Assign a random avatar. Users may be able to choose or reassign in future.
  const avatar = AVATARS[Math.floor(Math.random() * AVATARS.length)];
  const [registerResponse] = await Promise.all([
    postData(REGISTER_LOGIN_USER_URL, {
      emailAddress,
      password,
      displayName,
      avatar,
      allowDataCollection,
      referrer,
      isSigningUp: true,
    }),
  ]);

  return Boolean(registerResponse.success);
  // TODO: loginResponse.errors should give the error message to render
}

/**
 * Sends a patch call to update the user origin info in the database. Returns a promise indicating
 * whether the patch was successful.
 */
export async function addUserOriginInfo(userOrigin: IUserOrigin): Promise<boolean> {
  const [updateResponse] = await Promise.all([
    patchData(ADD_USER_ORIGIN_INFO_URL, { userOrigin: userOrigin }),
  ]);
  return Boolean(updateResponse.success);
}

export async function signoutUser(): Promise<boolean> {
  if (process.env.REACT_APP_USE_FCM === "true") {
    await unsubscribeFCMNotifications();
  } else {
    await unsubscribeNotifications();
  }
  const [registerResponse] = await Promise.all([postData(SIGNOUT_USER_URL)]);
  return Boolean(registerResponse.success);
}

export async function getIsEmailAddressAvailable(emailAddress: string): Promise<boolean> {
  const [registerResponse] = await Promise.all([
    postData(GET_IS_EMAIL_ADDRESS_AVAILABLE_URL, { emailAddress }),
  ]);
  return Boolean(registerResponse.available);
}

export async function sendResetPasswordEmail(emailAddress: string): Promise<boolean> {
  const [response] = await Promise.all([
    postData(SEND_RESET_PASSWORD_EMAIL_URL, { emailAddress })
  ]);
  return Boolean(response.success);
}

export async function resetPassword(token: string, password: string): Promise<boolean> {
  const [response] = await Promise.all([
    postData(RESET_PASSWORD_URL, { token, password })
  ]);
  return Boolean(response.success);
}